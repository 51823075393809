.installationInstructions {
  margin-top: 20px;
}
.installationInstructions ol {
  padding-left: 20px;
}
.installationInstructions button {
  color: #fff;
  background-color: #6c757d;
  border: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out;
}
