.heading {
  color: #000000;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 9px;
}
.title {
  color: #000000;
  height: 20px;
  font-size: 16px;
  margin: 0 0 24px 0;
}
.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 60px;
}
.col {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
}
.colRight {
  margin-top: 20px;
  padding-left: 20px;
  flex: 0 0 50%;
  max-width: 50%;
}
.isHidden {
  position: absolute;
  transform: translateX(-300%);
}
.isVisible {
  position: relative;
  transform: translateX(0);
}
