.inputStyle {
  height: 34px;
  width: 240px;
}
.legendStyle {
  font-size: 14px;
  letter-spacing: 0.2px;
  height: 16px;
  color: #141c29;
  margin-top: 10px;
}
.tableStyle {
  font-family: sans-serif;
  margin: 10px 0 0;
}
.btn {
  color: #fff;
  background-color: #6c757d;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #6c757d;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.signatureWrapper {
  background-color: #fff;
}
.previewImgCover {
  width: 99px;
  padding-right: 15px;
  vertical-align: middle;
  text-align: center;
}
.previewImg {
  object-fit: cover;
  width: 88px;
  height: 88px;
  border: 0;
  border-radius: 50%;
}
.innerWrap {
  color: #131313;
  font-family: sans-serif;
  vertical-align: middle;
}
.previewName {
  color: #000000;
  font-weight: bolder;
}
.previewPositionCover {
  color: #000000;
}
.previewPosition {
  color: #0075ff;
  padding-left: 5px;
}
.previewPhoneText {
  color: #5f5f5f;
}
.previewPhone {
  color: #0075ff;
}
