.rootTemplateContainer {
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: flex-start;
  flex: 0 0 50%;
}

.layout {
  margin: 20px 0 0 60px;
}
